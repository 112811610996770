import { Injectable } from '@angular/core';
import { MatDialog, MatSnackBar, MatSnackBarConfig } from '@angular/material';
import { Observable } from 'rxjs';
import { ErrorSnackComponent } from 'src/app/modules/shared/components/error-snack/error-snack.component';

import { ConfirmDialogComponent } from '../../modules/shared/components/confirm-dialog/confirm-dialog.component';

@Injectable({ providedIn: "root" })
export class ConfirmService {
  constructor(private snackBar: MatSnackBar, private dialog: MatDialog) { }

  public showConfirmMessage(
    message: string,
    snackBarConfig?: MatSnackBarConfig
  ) {
    let snackBarRef = this.snackBar.open(
      message,
      "X",
      Object.assign({ duration: 2000 }, snackBarConfig)
    );
    snackBarRef.onAction().subscribe(() => {
      snackBarRef.dismiss();
    });
  }

  public showErrorMessage(message: string, error: any) {
    // let errorMessage = error || "";
    // if (error && error.error && error.error.message) {
    //     errorMessage = error.error.message;
    // }
    if (error) {
      this.showErrorDetalis(message, error);
    } else {
      this.showConfirmMessage(`${message}`, { duration: -1, verticalPosition: "top", panelClass: "error" });
    }

  }

  public showErrorDetalis(message: string, details: any) {
    this.snackBar.openFromComponent(ErrorSnackComponent, {
      duration: -1, verticalPosition: "top", panelClass: "error", data: {
        message, details
      }
    });
  }

  public showConfirmDialog(
    title: string,
    message: string,
    okString?: string,
    cancelString?: string
  ): Observable<any> {
    let dialogRef = this.dialog.open(ConfirmDialogComponent, {
      disableClose: false
    });
    dialogRef.componentInstance.title = title;
    dialogRef.componentInstance.confirmMessage = message;
    if (okString) dialogRef.componentInstance.okString = okString;
    if (cancelString) dialogRef.componentInstance.cancelString = cancelString;
    return dialogRef.afterClosed();
  }
}

import { getLocaleCurrencySymbol } from '@angular/common';
import { Inject, LOCALE_ID, Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: "currencyLocal"
})
export class CurrencyLocalPipe implements PipeTransform {
    constructor(@Inject(LOCALE_ID) public locale: string) { }

    transform(value: number): any {
        return (
            new Intl.NumberFormat(this.locale, {
                style: "decimal",
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
            }).format(value) + ' ' + getLocaleCurrencySymbol(this.locale)
        );
    }
}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from '../../../../environments/environment';
import { FollowupDTO } from './../../models/followup';
import { SessionManagerService } from './session-manager.service';


@Injectable({
  providedIn: "root"
})
export class FollowupService {
  constructor(
    private httpClient: HttpClient,
    private session: SessionManagerService
  ) { }

  private get ROUTES() {
    return {
      followup: environment.baseUrl + "/api/followup"
    };
  }

  public getFollowups(): Observable<FollowupDTO[]> {
    return this.httpClient.get<FollowupDTO[]>(
      this.ROUTES.followup + `/${this.session.username}/${this.session.password}`
    );
  }
}


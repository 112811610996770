import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { Physiotherapist, PhysiotherapistBuilder } from '../models/physiotherapist';
import { Collaborator } from './../models/collaborator';
import { Followup } from './../models/followup';
import { AuthService } from './auth.service';
import { CollaboratorsService } from './backend/collaborators.service';
import { FollowupService } from './backend/followup.service';
import { RegistrationService } from './backend/registration.service';
import { ResponseObject, ResponseObjectWithId } from './response-objects';

@Injectable({
  providedIn: "root"
})
export class PhysiotherapistService {
  constructor(
    private backendService: RegistrationService,
    private authService: AuthService,
    private followupService: FollowupService,
    private collaboratorsService: CollaboratorsService
  ) { }

  public registerNew(
    name: string,
    surname: string,
    email: string,
    password: string
  ): Observable<ResponseObject> {
    return this.backendService
      .createPhysiotherapist(
        new PhysiotherapistBuilder()
          .build(name, surname, email, this.authService.digest(password))
          .toDTO()
      )
      .pipe(
        map(o => {
          let r: ResponseObject = {
            success: o.status ? (o.status.toLowerCase() == "ok") : false,
            status: o.status,
            message: o.message
          };
          return r;
        })
      );
  }

  public registerNewStudio(
    name: string,
    email: string,
    password: string
  ): Observable<ResponseObject> {
    return this.backendService
      .createPhysiotherapist(
        new PhysiotherapistBuilder()
          .buildStudio(name, email, this.authService.digest(password))
          .toDTO()
      )
      .pipe(
        map(o => {
          let r: ResponseObject = {
            success: o.status.toLowerCase() == "ok",
            status: o.status,
            message: o.message
          };
          return r;
        })
      );
  }

  public getPhysiotherapist(): Observable<Physiotherapist> {
    return this.backendService
      .getPhysiotherapist()
      .pipe(map(p => new Physiotherapist(p)));
  }

  public updatePhysiotherapist(
    physiotherapist: Physiotherapist,
    deleteImage: boolean,
    newPassword?: string
  ): Observable<ResponseObject> {
    return this.backendService
      .updatePhysiotherapist(physiotherapist.toDTO(), deleteImage, newPassword ? this.authService.digest(newPassword) : null)
      .pipe(
        map(r => {
          let o: ResponseObject = {
            success: r.status.toLowerCase() == "ok",
            message: r.message,
            status: r.status
          };
          return o;
        })
      );
  }

  public getCollaborators(): Observable<Collaborator[]> {
    return this.collaboratorsService.getCollaborators().pipe(map(dtos => dtos ? dtos.map(dto => new Collaborator(dto)) : null));
  }

  public getFollowups(): Observable<Followup[]> {
    return this.followupService.getFollowups().pipe(map(dtos => dtos ? dtos.map(dto => new Followup(dto)) : null));
  }

  public addCollaborator(
    collaborator: Collaborator
  ): Observable<ResponseObjectWithId> {
    return this.collaboratorsService.addCollaborator(collaborator.toDTO())
      .pipe(
        map(o => {
          let r: ResponseObjectWithId = {
            id: ("" + o.id).trim(),
            success: o.status.toLowerCase() == "ok",
            message: o.message,
            status: o.status
          };
          return r;
        })
      );;
  }

  public updateCollaborator(
    collaborator: Collaborator
  ): Observable<ResponseObject> {
    return this.collaboratorsService.updateCollaborator(collaborator.toDTO())
      .pipe(
        map(r => {
          let o: ResponseObject = {
            success: r.status.toLowerCase() == "ok",
            message: r.message,
            status: r.status
          };
          return o;
        })
      );;
  }

  public deleteCollaborator(id: string): Observable<ResponseObject> {
    return this.collaboratorsService.deleteCollaborator(id).pipe(
      map(r => {
        let ro: ResponseObject = {
          success: (r.status + "").toLowerCase() !== "error",
          status: r.status,
          message: r.message
        };
        return ro;
      })
    );
  }
}

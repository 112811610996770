import { PasswordDialogComponent } from 'src/app/modules/master/modules/admin/password-dialog/password-dialog.component';
import { registerLocaleData } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import localeIt from '@angular/common/locales/it';
import { LOCALE_ID, NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgxUiLoaderConfig, NgxUiLoaderHttpModule, NgxUiLoaderModule, POSITION, SPINNER } from 'ngx-ui-loader';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ErrorInterceptor } from './commons/services/interceptors/error.interceptor';
import { LoginModule } from './modules/login/login.module';
import { SharedModule } from './modules/shared/shared.module';
import { ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule, MatInputModule } from '@angular/material';
import { AppMaterialModule } from './modules/app-material/app-material.module';

registerLocaleData(localeIt);

const ngxUiLoaderConfig: NgxUiLoaderConfig = {
  fgsType: SPINNER.cubeGrid, // foreground spinner type
  hasProgressBar: false,
  fgsColor: "#1171a3",
  fgsSize: 30,
  fgsPosition: POSITION.bottomCenter
};

@NgModule({
  declarations: [AppComponent, PasswordDialogComponent],
  entryComponents: [PasswordDialogComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    LoginModule,
    HttpClientModule,
    BrowserAnimationsModule,
    SharedModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    MatFormFieldModule,
    MatInputModule,
    AppMaterialModule,
    NgxUiLoaderModule.forRoot(ngxUiLoaderConfig),
    //NgxUiLoaderRouterModule,
    NgxUiLoaderHttpModule.forRoot({ showForeground: true })
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptor,
      multi: true
    },
    {
      provide: LOCALE_ID,
      useValue: "it-IT"
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

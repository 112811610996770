import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ErrorLogService } from '../error-log.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(private errorService: ErrorLogService, private router: Router) { }
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      map(resp => {
        if (resp instanceof HttpResponse) {
          // Do whatever you want with the response.
          if (resp.body && resp.body.status == "error") {
            // throwError(resp.body.description);
            // return resp.clone({ body: "" });
            const message = resp.body.description || resp.body.message;
            if (message == 'User e/o password wrong') {
              this.router.navigate(['/login']);
            }
            throw new HttpErrorResponse({
              error: {
                message
              },
              headers: resp.headers,
              status: 500,
              statusText: 'Errore',
              url: resp.url
            });
          } else {
            return resp;
          }

        }
      })
    );
  }
}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { environment } from '../../../../environments/environment';
import { UtenteDTO } from '../../models/physiotherapist';
import { ResponseBase } from './response-base';
import { SessionManagerService } from './session-manager.service';

@Injectable({
  providedIn: "root"
})
export class RegistrationService {
  constructor(
    private httpClient: HttpClient,
    private session: SessionManagerService
  ) { }

  private get ROUTES() {
    return {
      forgotPassword: environment.baseUrl + "/api/passworddimenticata",
      login: environment.baseUrl + "/api/login",
      getInvoiceLogo: environment.baseUrl + "/api/logofatture",
      physiotherapist: environment.baseUrl + "/api/registrazione",
      checkAdminPassword: environment.baseUrl + "/api/adminpassword"
    };
  }

  public forgotPassword(username: string): Observable<ResponseBase> {
    return this.httpClient.post<ResponseBase>(this.ROUTES.forgotPassword, {
      sessioneutente: username
    });
  }

  public doLogin(username: string, password: string): Observable<LoginData> {
    return this.httpClient.post<LoginData>(this.ROUTES.login, {
      sessioneutente: username,
      sessionepassword: password

    });
  }

  public getInvoiceLogo(): Observable<ResponseBase> {
    return this.httpClient.get(
      this.ROUTES.getInvoiceLogo +
      `/${this.session.username}/${this.session.password}`
    );
  }

  public getPhysiotherapist(): Observable<UtenteDTO> {
    return this.httpClient.get<UtenteDTO>(
      this.ROUTES.physiotherapist +
      `/${this.session.username}/${this.session.password}`
    );
  }

  public createPhysiotherapist(
    physiotherapist: UtenteDTO
  ): Observable<ResponseBase> {
    return this.httpClient.post(
      this.ROUTES.physiotherapist,
      physiotherapist,
      {
        responseType: 'text'
      }
    ).pipe(map(r => JSON.parse(r)));
  }

  public updatePhysiotherapist(
    physiotherapist: UtenteDTO,
    deleteImage: boolean,
    newPassword?: string
  ): Observable<ResponseBase> {
    let formData: FormData = new FormData();
    if (physiotherapist.abbonamentoscaduto != undefined) formData.append("abbonamentoscaduto", physiotherapist.abbonamentoscaduto.toString());
    if (physiotherapist.aifiId != undefined) formData.append("aifiId", physiotherapist.aifiId);
    if (physiotherapist.cap != undefined) formData.append("cap", physiotherapist.cap);
    if (physiotherapist.cellulare != undefined) formData.append("cellulare", physiotherapist.cellulare);
    if (physiotherapist.cf != undefined) formData.append("cf", physiotherapist.cf);
    if (physiotherapist.codiceidentificativo != undefined) formData.append("codiceidentificativo", physiotherapist.codiceidentificativo);
    if (physiotherapist.cognome != undefined) formData.append("cognome", physiotherapist.cognome);
    if (physiotherapist.comune != undefined) formData.append("comune", physiotherapist.comune);
    if (physiotherapist.comunedinascita != undefined) formData.append("comunedinascita", physiotherapist.comunedinascita);
    if (physiotherapist.datanascita != undefined) formData.append("datanascita", physiotherapist.datanascita.toString());
    if (physiotherapist.dataregistrazione != undefined) formData.append("dataregistrazione", physiotherapist.dataregistrazione.toString());
    if (physiotherapist.foto != undefined) formData.append("datanascita", physiotherapist.foto);
    if (physiotherapist.idconvenzione != undefined) formData.append("idconvenzione", physiotherapist.idconvenzione);
    if (physiotherapist.indirizzo != undefined) formData.append("indirizzo", physiotherapist.indirizzo);
    if (physiotherapist.modified != undefined) formData.append("modified", physiotherapist.modified.toString());
    if (physiotherapist.n_collaboratori_da_inserire != undefined) formData.append("n_collaboratori_da_inserire", physiotherapist.n_collaboratori_da_inserire.toString());
    if (physiotherapist.natoa != undefined) formData.append("natoa", physiotherapist.natoa);
    if (physiotherapist.nazione != undefined) formData.append("nazione", physiotherapist.nazione);
    if (physiotherapist.nome != undefined) formData.append("nome", physiotherapist.nome);
    if (physiotherapist.notefattura != undefined) formData.append("notefattura", physiotherapist.notefattura);
    if (physiotherapist.numerocivico != undefined) formData.append("numerocivico", physiotherapist.numerocivico);
    if (physiotherapist.pec != undefined) formData.append("pec", physiotherapist.pec);
    if (physiotherapist.percentualesconto != undefined) formData.append("percentualesconto", physiotherapist.percentualesconto);
    if (physiotherapist.piva != undefined) formData.append("piva", physiotherapist.piva);
    if (physiotherapist.provincia != undefined) formData.append("provincia", physiotherapist.provincia);
    if (physiotherapist.provinciadinascita != undefined) formData.append("provinciadinascita", physiotherapist.provinciadinascita);
    if (physiotherapist.regime != undefined) formData.append("regime", physiotherapist.regime);
    if (physiotherapist.regionediaffiliazioneaifi != undefined) formData.append("regionediaffiliazioneaifi", physiotherapist.regionediaffiliazioneaifi);
    if (physiotherapist.sedelegale != undefined) formData.append("sedelegale", physiotherapist.sedelegale);
    if (physiotherapist.sesso != undefined) formData.append("sesso", physiotherapist.sesso);
    if (physiotherapist.sitoweb != undefined) formData.append("sitoweb", physiotherapist.sitoweb);
    if (physiotherapist.specializzazione != undefined) formData.append("specializzazione", physiotherapist.specializzazione);
    if (physiotherapist.studio != undefined) formData.append("studio", physiotherapist.studio.toString());
    if (physiotherapist.superuser != undefined) formData.append("superuser", physiotherapist.superuser.toString());
    if (physiotherapist.telefono != undefined) formData.append("telefono", physiotherapist.telefono);
    if (physiotherapist.titolo != undefined) formData.append("titolo", physiotherapist.titolo);
    if (physiotherapist.username != undefined) formData.append("username", physiotherapist.username);
    if (physiotherapist.utentegratuito != undefined) formData.append("utentegratuito", physiotherapist.utentegratuito.toString());
    if (physiotherapist._id != undefined) formData.append("_id", physiotherapist._id);
    if (physiotherapist.albo_id != undefined) formData.append("albo_id", physiotherapist.albo_id);
    if (physiotherapist.comune_albo != undefined) formData.append("comune_albo", physiotherapist.comune_albo);
    if (physiotherapist.tesserasanitaria_username != undefined) formData.append("tesserasanitaria_username", physiotherapist.tesserasanitaria_username);
    if (physiotherapist.tesserasanitaria_password != undefined) formData.append("tesserasanitaria_password", physiotherapist.tesserasanitaria_password);
    if (physiotherapist.tesserasanitaria_PIN != undefined) formData.append("tesserasanitaria_PIN", physiotherapist.tesserasanitaria_PIN);
    if (physiotherapist.tesserasanitaria_codiceregionale != undefined) formData.append("tesserasanitaria_codiceregionale", physiotherapist.tesserasanitaria_codiceregionale);
    if (physiotherapist.tesserasanitaria_codiceasl != undefined) formData.append("tesserasanitaria_codiceasl", physiotherapist.tesserasanitaria_codiceasl);
    if (physiotherapist.tesserasanitaria_codicessa != undefined) formData.append("tesserasanitaria_codicessa", physiotherapist.tesserasanitaria_codicessa);
    if (physiotherapist.logofatture) formData.append("logo", physiotherapist.logofatture);
    formData.append("deleteimage", deleteImage ? "1" : "0");

    if (newPassword) formData.append("password", newPassword);

    return this.httpClient.put(
      this.ROUTES.physiotherapist +
      `/${this.session.username}/${this.session.password}`,
      formData
    ).pipe(
      tap(() => {
        if (newPassword) {
          this.session.password = newPassword
        }
      }));
  }

  public checkAdminPassword(adminpassword: string): Observable<{ success: boolean }> {
    return this.httpClient.post<{ success: boolean }>(this.ROUTES.checkAdminPassword, {
      sessioneutente: this.session.username,
      sessionepassword: this.session.password,
      adminpassword
    });
  }
}

export interface LoginData extends ResponseBase {
  superuser?: string;
  tesseraaifi?: string;
  idconvenzione?: string;
  utentegratuito?: string;
  studio?: string;
  tesserasanitaria?: string;
  datascadenza?: Date;
}

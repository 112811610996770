import { Person } from 'src/app/commons/models/person';

import { PersonaDTO } from './person';

export interface FollowupDTO {
    idanagrafe?: PersonaDTO;
    idepisodio?: string;
}

export class Followup {
    person: Person;
    episodeID: string;
    constructor(source: FollowupDTO) {
        if (source) {
            this.person = new Person(source.idanagrafe);
            this.episodeID = source.idepisodio;
        }
    }
}
import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { BehaviorSubject } from "rxjs";
import { finalize, first } from "rxjs/operators";
import { AuthService } from "../../../commons/services/auth.service";

@Component({
  selector: "app-recover-password",
  templateUrl: "./recover-password.component.html",
  styleUrls: ["./recover-password.component.scss"]
})
export class RecoverPasswordComponent implements OnInit {
  private loadingSubject = new BehaviorSubject<boolean>(false);
  public loading$ = this.loadingSubject.asObservable();

  form: FormGroup;
  error = false;
  errorMessage = "";
  returnUrl: string;

  constructor(
    private auth: AuthService,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private authService: AuthService
  ) {}

  ngOnInit() {
    // reset login status
    this.authService.logout();

    // get return url from route parameters or default to '/'
    this.returnUrl = "/login";

    this.form = this.fb.group({
      email: ["", Validators.compose([Validators.required, Validators.email])]
    });
  }

  recover() {
    if (this.form.valid) {
      this.loadingSubject.next(true);
      this.error = false;
      this.authService
        .recoverPasswordViaEmail(this.form.value.email)
        .pipe(
          finalize(() => this.loadingSubject.next(false)),
          first()
        )
        .subscribe(
          result => {
            if (result.success) {
              console.log("Recover password email sent successfully", result);
              this.router.navigate([this.returnUrl]);
            } else {
              this.error = true;
              this.errorMessage = "Attenzione: email non corretta";
              console.log("Recover password failed", result.message);
            }
          },
          error => {
            this.error = true;
            console.error("Error", error);
          }
        );
    }
  }
}

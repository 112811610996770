import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material';

@Component({
  selector: "confirm-dialog",
  templateUrl: "./confirm-dialog.component.html",
  styleUrls: ["./confirm-dialog.component.scss"]
})
export class ConfirmDialogComponent {
  constructor(public dialogRef: MatDialogRef<ConfirmDialogComponent>) { }

  public confirmMessage: string;
  public title: string;

  public okString: string = "Conferma";
  public cancelString: string = "Annulla";
}

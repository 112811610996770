export class Base {
  private _id: string;
  private _createdAt: Date;
  private _updatedAt: Date;

  constructor(source?: any) {
    if (source) {
      this._id = source.id || source._id;
      this._createdAt = source.createdAt;
      this._updatedAt = source.updatedAt;
    }
  }

  public static compare(b1: Base, b2: Base): boolean {
    return b1 && b2 ? b1.id === b2.id : b1 === b2;
  }

  /**
   * Returns the id
   * @returns {string} id
   */
  public get id(): string {
    return this._id;
  }

  /**
   * Sets the id
   * @param {string} id
   */
  public set id(value: string) {
    this._id = value;
  }

  /**
   * Returns the creation date.
   * @returns {Date} creation date
   */
  public get createdAt(): Date {
    return this._createdAt;
  }

  /**
   * Sets the creation date.
   * @param {Date}
   */
  public set createdAt(value: Date) {
    this._createdAt = value;
  }

  /**
   * Returns the update date.
   * @returns {Date}
   */
  public get updatedAt(): Date {
    return this._updatedAt;
  }

  /**
   * Sets the update date.
   * @param {Date} update date
   */
  public set updatedAt(value: Date) {
    this._updatedAt = value;
  }
}

import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { finalize, first } from 'rxjs/operators';

import { AuthService } from '../../commons/services/auth.service';

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"]
})
export class LoginComponent implements OnInit {
  private loadingSubject = new BehaviorSubject<boolean>(false);
  public loading$ = this.loadingSubject.asObservable();

  form: FormGroup;
  error = false;
  errorMessage = "";
  returnUrl: string;
  hide: boolean = true;

  constructor(
    private auth: AuthService,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private authService: AuthService
  ) { }

  ngOnInit() {
    // reset login status
    this.authService.logout();

    // get return url from route parameters or default to '/'
    this.returnUrl = this.route.snapshot.queryParams["returnUrl"] || "/";

    this.form = this.fb.group({
      email: ["", Validators.compose([Validators.required, Validators.email])],
      password: ["", Validators.required]
    });
  }

  login() {
    if (this.form.valid) {
      this.loadingSubject.next(true);
      this.error = false;
      this.authService
        .login(this.form.value.email, this.form.value.password)
        .pipe(
          finalize(() => this.loadingSubject.next(false)),
          first()
        )
        .subscribe(
          result => {
            if (result.success) {
              console.log("Login successful", result);
              this.router.navigate([this.returnUrl]);
            } else {
              this.error = true;
              this.errorMessage = "Attenzione: email o password non corretta";
              console.log("Login failed", result.message);
            }
          },
          error => {
            this.error = true;
            this.errorMessage = error.error.message;
            console.error("Error", error);
          }
        );
    }
  }
}

import { Injectable } from "@angular/core";
import { Subject } from "rxjs";

@Injectable({
  providedIn: "root"
})
export class ErrorLogService {
  private _errorEmitter = new Subject<string>();
  constructor() {}

  public submitNewError(message: string) {
    this._errorEmitter.next(message);
  }

  public get errorMessageSource(): Subject<string> {
    return this._errorEmitter;
  }
}

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import * as sha1 from 'sha1';

import { RegistrationService } from './backend/registration.service';
import { SessionManagerService } from './backend/session-manager.service';
import { ResponseObject } from './response-objects';

@Injectable({
  providedIn: "root"
})
export class AuthService {
  private _salt = ""; // empty for previous accounts
  private _isSuperuser: boolean;
  private _isLoggedIn: boolean;
  isAdminLoggedIn: boolean;
  private _isStudio: boolean;
  private _enableTS: boolean

  endDate: Date;

  constructor(
    private backendLogin: RegistrationService,
    private sessionManagerService: SessionManagerService
  ) {
    this._isLoggedIn = !(
      typeof sessionManagerService.username == "undefined" ||
      sessionManagerService.username == null ||
      typeof sessionManagerService.password == "undefined" ||
      sessionManagerService.password == null
    );
    if (this._isLoggedIn) {
      this._doLogin(this.sessionManagerService.username, this.sessionManagerService.password, !this.sessionManagerService.useSessionStorage).subscribe();
    }
  }

  public digest(password: string): string {
    return sha1(password + this._salt);
  }

  public login(
    username: string,
    password: string,
    rememberMe: boolean = true
  ): Observable<ResponseObject> {
    let hashedPassword = this.digest(password);
    return this._doLogin(username, hashedPassword, rememberMe);
  }

  private _doLogin(username, hashedPassword, rememberMe: boolean = true): Observable<ResponseObject> {
    return this.backendLogin.doLogin(username, hashedPassword).pipe(
      tap(o => {
        this.sessionManagerService.useSessionStorage = !rememberMe;
        this.sessionManagerService.username = username;
        this.sessionManagerService.password = hashedPassword;
        this._isSuperuser = o ? JSON.parse(o.superuser) : false;
        this._isStudio = o ? JSON.parse(o.studio) : false;
        this._enableTS = (o && o.tesserasanitaria) ? JSON.parse(o.tesserasanitaria) : false;
        this.endDate = o.datascadenza;
      }),
      map(o => {
        let r: ResponseObject = {
          success:
            o != null &&
            o.status != null &&
            (o.status.toLowerCase() == "ok" ||
              o.status.toLowerCase() == "warning"),
          status: o ? o.status : "",
          message: o ? o.message : ""
        };
        return r;
      }),
      tap(f => {
        if (f.success) {
          this._isLoggedIn = true;
        }
      })
    );
  }

  public checkAdminPassword(passwd: string): Observable<boolean> {
    const hash = this.digest(passwd);
    return this.backendLogin.checkAdminPassword(hash).pipe(
      map(o => {
        return o.success;
      }),
      tap(val => {
        if (val) {
          this.sessionManagerService.adminpassword = hash;
        }
      })
    );
  }

  /**
   * Getter isLoggedIn
   * @return {boolean}
   */
  public get isLoggedIn(): boolean {
    return this._isLoggedIn;
  }

  /**
   * Getter isSuperuser
   * @return {boolean}
   */
  public get isSuperuser(): boolean {
    return this._isSuperuser;
  }



  public get isStudio(): boolean {
    return this._isStudio;
  }

  public get hasTesseraSanitaria(): boolean {
    return this._enableTS;
  }

  public logout() {
    this.sessionManagerService.clean();
    this._isLoggedIn = false;
  }

  public recoverPasswordViaEmail(username: string): Observable<ResponseObject> {
    return this.backendLogin.forgotPassword(username).pipe(
      map(o => {
        let r: ResponseObject = {
          success:
            o != null &&
            o.status != null &&
            o.status.toLowerCase() != "warning",
          status: o ? o.status : "",
          message: o ? o.message : ""
        };
        return r;
      })
    );
  }
}

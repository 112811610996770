import { FormGroup } from "@angular/forms";

import { Agreement } from "./agreement";
import { Base } from "./base";

export class PhysiotherapistBuilder {
  public build(
    name: string,
    surname: string,
    email: string,
    password: string
  ): Physiotherapist {
    let p = new Physiotherapist();
    p.name = name;
    p.lastName = surname;
    p.username = email;
    p.password = password;
    return p;
  }

  public buildStudio(
    name: string,
    email: string,
    password: string
  ): Physiotherapist {
    let p = new Physiotherapist();
    p.lastName = name;
    p.username = email;
    p.password = password;
    p.isStudio = true;
    return p;
  }
}

export class Physiotherapist extends Base {
  private _idAgreement: string;
  private _agreement: Agreement;
  private _discountPercentage: string;
  private _name: string;
  private _lastName: string;
  private _specialization: string;
  private _invoiceNotes: string;
  private _aifiID: string;
  private _aifiMembershipRegion: string;
  private _gender: string;
  private _telephone: string;
  private _mobilePhone: string;
  private _email: string;
  private _birthDate: Date;
  private _birthPlace: string;
  private _title: string;
  private _birthProvince: string;
  private _birthDistrict: string;
  private _postcode: string;
  private _address: string;
  private _district: string;
  private _province: string;
  private _nation: string;
  private _houseNumber: string;
  private _fc: string;
  private _regime: string;
  private _registeredOffice: string;
  private _website: string;
  private _IVAentry: string;
  private _username: string;
  private _password?: string;
  private _superuser: boolean;
  private _photo: any;
  private _invoiceLogo: any;
  private _expiredSubscription: boolean;
  private _freeUser: boolean;
  private _maxCollaborators: number;
  private _isStudio: boolean;
  private _cem: string;
  private _identifier: string;
  private _expirationDate: Date;


  alboId: string;
  alboDistrict: string;
  //Integrazione con invio al sistema di Tessera Sanitaria
  TSUsername: string;
  TSPassword: string;
  TSPIN: string;
  TSRegionCode: string;
  TSAslCode: string;
  TSSSACode: string;
  TSEnabled: boolean;
  lastLogin: string;
  documentDiskSize: string;
  episodes: number;
  events: number;
  invoices: number;
  patients: number;
  // Calendar
  calendar: boolean;
  calendarId: string;
  oauthToken: string;


  constructor(source?: UtenteDTO) {
    super(source);
    if (source) {
      this.id = source._id || source.id;
      this.updatedAt = source.modified;
      this.createdAt = source.dataregistrazione;
      this.idAgreement = source.idconvenzione;
      this.discountPercentage = source.percentualesconto;
      this.name = source.nome;
      this.lastName = source.cognome;
      this.specialization = source.specializzazione;
      this.invoiceNotes = source.notefattura;
      this.aifiID = source.aifiId;
      this.aifiMembershipRegion = source.regionediaffiliazioneaifi;
      this.gender = source.sesso;
      this.telephone = source.telefono;
      this.mobilePhone = source.cellulare;
      this.email = source.email;
      this.birthDate = source.datanascita;
      this.birthPlace = source.natoa;
      this.title = source.titolo;
      this.birthProvince = source.provinciadinascita;
      this.birthDistrict = source.comunedinascita;
      this.postcode = source.cap;
      this.address = source.indirizzo;
      this.district = source.comune;
      this.province = source.provincia;
      this.nation = source.nazione;
      this.houseNumber = source.numerocivico;
      this.fc = source.cf;
      this.regime = source.regime;
      this.registeredOffice = source.sedelegale;
      this.website = source.sitoweb;
      this.IVAentry = source.piva;
      this.username = source.username;
      this.password = source.password;
      this.superuser = source.superuser;
      this.photo = source.foto;
      this.invoiceLogo = source.logofatture;
      this.expiredSubscription = source.abbonamentoscaduto;
      this.freeUser = source.utentegratuito;
      this.maxCollaborators = source.n_collaboratori_da_inserire;
      this.isStudio = source.studio;
      this.cem = source.pec;
      this.identifier = source.codiceidentificativo;
      this.alboId = source.albo_id;
      this.alboDistrict = source.comune_albo;
      this.TSUsername = source.tesserasanitaria_username;
      this.TSPassword = source.tesserasanitaria_password;
      this.TSPIN = source.tesserasanitaria_PIN;
      this.TSRegionCode = source.tesserasanitaria_codiceregionale;
      this.TSAslCode = source.tesserasanitaria_codiceasl;
      this.TSSSACode = source.tesserasanitaria_codicessa;
      this.TSEnabled = source.tesserasanitaria_enabled;
      this.lastLogin = source.lastlogin;
      this.documentDiskSize = source.documentidisksize;
      this.episodes = source.episodi;
      this.events = source.eventi;
      this.invoices = source.fatture;
      this.patients = source.anagrafe;
      this.expirationDate = source.datascadenza;
      this.calendar = source.calendar;
      this.calendarId = source.calendar_id;
      this.oauthToken = source.oauth_token;
    }
  }

  static fromFormGroup(formGroup: FormGroup): Physiotherapist {
    const formModel = formGroup.value;

    let physioTherapist: Physiotherapist = new Physiotherapist(null);

    // Dati personali
    physioTherapist.name = formModel.name;
    physioTherapist.lastName = formModel.lastname;
    physioTherapist.gender = formModel.sex;
    physioTherapist.fc = formModel.codiceFiscale;
    physioTherapist.telephone = formModel.telephone;
    physioTherapist.mobilePhone = formModel.mobile;
    physioTherapist.email = formModel.email;
    physioTherapist.username = formModel.email;
    physioTherapist.IVAentry = formModel.partitaIVA;
    physioTherapist.superuser = formModel.superuser;

    // Dati professionali
    physioTherapist.title = formModel.title;
    physioTherapist.specialization = formModel.specialisation;
    physioTherapist.aifiID = formModel.taxNumberAIFI;
    physioTherapist.aifiMembershipRegion = formModel.regionAffiliationAIFI;
    physioTherapist.cem = formModel.pec;
    physioTherapist.identifier = formModel.identificationCode;
    physioTherapist.alboId = formModel.alboId;
    physioTherapist.alboDistrict = formModel.alboDistrict;

    // Impostazioni ricevute/fatture
    physioTherapist.invoiceNotes = formModel.invoiceNote;
    physioTherapist.regime = formModel.fiscalRegime;
    physioTherapist.invoiceLogo = formModel.logo;

    // Indirizzo
    physioTherapist.address = formModel.streetName;
    physioTherapist.houseNumber = formModel.streetNumber;
    physioTherapist.district = formModel.comune;
    physioTherapist.postcode = formModel.cap;
    physioTherapist.province = formModel.province;
    physioTherapist.nation = formModel.country;

    //Tessera sanitaria
    physioTherapist.TSUsername = formModel.TSUsername;
    physioTherapist.TSPassword = formModel.TSPassword;
    physioTherapist.TSPIN = formModel.TSPIN;
    physioTherapist.TSRegionCode = formModel.TSRegionCode;
    physioTherapist.TSAslCode = formModel.TSAslCode;
    physioTherapist.TSSSACode = formModel.TSSSACode;

    physioTherapist.lastLogin = formModel.lastLogin;
    physioTherapist.documentDiskSize = formModel.documentDiskSize;
    physioTherapist.events = formModel.eventi;
    physioTherapist.invoices = formModel.fatture;
    physioTherapist.patients = formModel.anagrafe;


    return physioTherapist;
  }

  public toDTO(): UtenteDTO {
    let u: UtenteDTO = {
      _id: this.id,
      idconvenzione: this.idAgreement,
      percentualesconto: this.discountPercentage,
      nome: this.name,
      cognome: this.lastName,
      specializzazione: this.specialization,
      notefattura: this.invoiceNotes,
      aifiId: this.aifiID,
      regionediaffiliazioneaifi: this.aifiMembershipRegion,
      sesso: this.gender,
      telefono: this.telephone,
      email: this.email,
      cellulare: this.mobilePhone,
      datanascita: this.birthDate,
      natoa: this.birthPlace,
      titolo: this.title,
      provinciadinascita: this.birthProvince,
      comunedinascita: this.birthDistrict,
      cap: this.postcode,
      indirizzo: this.address,
      comune: this.district,
      provincia: this.province,
      nazione: this.nation,
      numerocivico: this.houseNumber,
      cf: this.fc,
      regime: this.regime,
      sedelegale: this.registeredOffice,
      sitoweb: this.website,
      piva: this.IVAentry,
      username: this.username,
      password: this.password,
      superuser: this.superuser,
      foto: this.photo,
      logofatture: this.invoiceLogo,
      abbonamentoscaduto: this.expiredSubscription,
      utentegratuito: this.freeUser,
      n_collaboratori_da_inserire: this.maxCollaborators,
      dataregistrazione: this.createdAt,
      studio: this.isStudio,
      modified: this.updatedAt,
      pec: this.cem,
      codiceidentificativo: this.identifier,
      albo_id: this.alboId,
      comune_albo: this.alboDistrict,
      tesserasanitaria_username: this.TSUsername,
      tesserasanitaria_password: this.TSPassword,
      tesserasanitaria_PIN: this.TSPIN,
      tesserasanitaria_codiceregionale: this.TSRegionCode,
      tesserasanitaria_codiceasl: this.TSAslCode,
      tesserasanitaria_codicessa: this.TSSSACode,
      lastlogin: this.lastLogin,
      documentidisksize: this.documentDiskSize,
      episodi: this.episodes,
      eventi: this.events,
      fatture: this.invoices,
      anagrafe: this.patients,
      datascadenza: this.expirationDate,
      calendar: this.calendar,
      calendar_id: this.calendarId,
      oauth_token: this.oauthToken
    };
    return u;
  }

  /**
   * Getter identifier
   * E-Invoicing (Fatturazione Elettronica)
   * @return {string}
   */
  public get identifier(): string {
    return this._identifier;
  }

  /**
   * Setter identifier
   * E-Invoicing (Fatturazione Elettronica)
   * @param {string} value
   */
  public set identifier(value: string) {
    this._identifier = value;
  }

  /**
   * Getter agreement
   * @return {Agreement}
   */
  public get agreement(): Agreement {
    return this._agreement;
  }

  /**
   * Setter agreement
   * @param {Agreement} value
   */
  public set agreement(value: Agreement) {
    this._agreement = value;
    this._idAgreement = value ? value.id : null;
  }

  /**
   * Getter idAgreement
   * @return {string}
   */
  public get idAgreement(): string {
    return this._agreement ? this._agreement.id : this._idAgreement;
  }

  /**
   * Getter discountPercentage
   * @return {string}
   */
  public get discountPercentage(): string {
    return this._discountPercentage;
  }

  /**
   * Getter name
   * @return {string}
   */
  public get name(): string {
    return this._name;
  }

  /**
   * Getter lastName
   * @return {string}
   */
  public get lastName(): string {
    return this._lastName;
  }

  /**
   * Getter specialization
   * @return {string}
   */
  public get specialization(): string {
    return this._specialization;
  }

  /**
   * Getter invoiceNotes
   * @return {string}
   */
  public get invoiceNotes(): string {
    return this._invoiceNotes;
  }

  /**
   * Getter aifiID
   * @return {string}
   */
  public get aifiID(): string {
    return this._aifiID;
  }

  /**
   * Getter membershipRegion
   * @return {string}
   */
  public get aifiMembershipRegion(): string {
    return this._aifiMembershipRegion;
  }

  /**
   * Getter gender
   * @return {string}
   */
  public get gender(): string {
    return this._gender;
  }

  /**
   * Getter telephone
   * @return {string}
   */
  public get telephone(): string {
    return this._telephone;
  }

  /**
   * Getter mobilePhone
   * @return {string}
   */
  public get mobilePhone(): string {
    return this._mobilePhone;
  }

  /**
  * Getter email
  * @return {string}
  */
  public get email(): string {
    return this._email;
  }

  /**
   * Getter birthDate
   * @return {Date}
   */
  public get birthDate(): Date {
    return this._birthDate;
  }

  /**
 * Getter expirationDate
 * @return {Date}
 */
  public get expirationDate(): Date {
    return this._expirationDate;
  }

  /**
   * Getter birthPlace
   * @return {string}
   */
  public get birthPlace(): string {
    return this._birthPlace;
  }

  /**
   * Getter title
   * @return {string}
   */
  public get title(): string {
    return this._title;
  }

  /**
   * Getter birthProvince
   * @return {string}
   */
  public get birthProvince(): string {
    return this._birthProvince;
  }

  /**
   * Getter birthDistrict
   * @return {string}
   */
  public get birthDistrict(): string {
    return this._birthDistrict;
  }

  /**
   * Getter postcode
   * @return {string}
   */
  public get postcode(): string {
    return this._postcode;
  }

  /**
   * Getter address
   * @return {string}
   */
  public get address(): string {
    return this._address;
  }

  /**
   * Getter district
   * @return {string}
   */
  public get district(): string {
    return this._district;
  }

  /**
   * Getter province
   * @return {string}
   */
  public get province(): string {
    return this._province;
  }

  /**
   * Getter nation
   * @return {string}
   */
  public get nation(): string {
    return this._nation;
  }

  /**
   * Getter houseNumber
   * @return {string}
   */
  public get houseNumber(): string {
    return this._houseNumber;
  }

  /**
   * Getter fc
   * @return {string}
   */
  public get fc(): string {
    return this._fc;
  }

  /**
   * Getter regime
   * @return {string}
   */
  public get regime(): string {
    return this._regime;
  }

  /**
   * Getter registeredOffice
   * @return {string}
   */
  public get registeredOffice(): string {
    return this._registeredOffice;
  }

  /**
   * Getter website
   * @return {string}
   */
  public get website(): string {
    return this._website;
  }

  /**
   * Getter IVAentry
   * @return {string}
   */
  public get IVAentry(): string {
    return this._IVAentry;
  }

  /**
   * Getter username
   * @return {string}
   */
  public get username(): string {
    return this._username;
  }

  /**
   * Getter password
   * @return {string}
   */
  public get password(): string {
    return this._password;
  }

  /**
   * Getter superuser
   * @return {boolean}
   */
  public get superuser(): boolean {
    return this._superuser;
  }

  /**
   * Getter photo
   * @return {any}
   */
  public get photo(): any {
    return this._photo;
  }

  /**
   * Getter invoicingLogo
   * @return {any}
   */
  public get invoiceLogo(): any {
    return this._invoiceLogo;
  }

  /**
   * Getter expiredSubscription
   * @return {boolean}
   */
  public get expiredSubscription(): boolean {
    return this._expiredSubscription;
  }

  /**
   * Getter freeUser
   * @return {boolean}
   */
  public get freeUser(): boolean {
    return this._freeUser;
  }

  /**
   * Getter maxCollaborators
   * @return {number}
   */
  public get maxCollaborators(): number {
    return this._maxCollaborators;
  }

  /**
   * Getter isStudio
   * @return {boolean}
   */
  public get isStudio(): boolean {
    return this._isStudio;
  }

  /**
   * Getter cem
   * @return {string}
   */
  public get cem(): string {
    return this._cem;
  }

  /**
   * Setter idAgreement
   * @param {string} value
   */
  public set idAgreement(value: string) {
    this._idAgreement = value;
    if (this._agreement && this._agreement.id !== value) {
      this._agreement = null;
    }
  }

  /**
   * Setter discountPercentage
   * @param {string} value
   */
  public set discountPercentage(value: string) {
    this._discountPercentage = value;
  }

  /**
   * Setter name
   * @param {string} value
   */
  public set name(value: string) {
    this._name = value;
  }

  /**
   * Setter lastName
   * @param {string} value
   */
  public set lastName(value: string) {
    this._lastName = value;
  }

  /**
   * Setter specialization
   * @param {string} value
   */
  public set specialization(value: string) {
    this._specialization = value;
  }

  /**
   * Setter invoiceNotes
   * @param {string} value
   */
  public set invoiceNotes(value: string) {
    this._invoiceNotes = value;
  }

  /**
   * Setter aifiID
   * @param {string} value
   */
  public set aifiID(value: string) {
    this._aifiID = value;
  }

  /**
   * Setter membershipRegion
   * @param {string} value
   */
  public set aifiMembershipRegion(value: string) {
    this._aifiMembershipRegion = value;
  }

  /**
   * Setter gender
   * @param {string} value
   */
  public set gender(value: string) {
    this._gender = value;
  }

  /**
   * Setter telephone
   * @param {string} value
   */
  public set telephone(value: string) {
    this._telephone = value;
  }

  /**
   * Setter mobilePhone
   * @param {string} value
   */
  public set mobilePhone(value: string) {
    this._mobilePhone = value;
  }

  /**
 * Setter email
 * @param {string} value
 */
  public set email(value: string) {
    this._email = value;
  }

  /**
   * Setter birthDate
   * @param {Date} value
   */
  public set birthDate(value: Date) {
    this._birthDate = value;
  }

  /**
 * Setter expirationDate
 * @param {Date} value
 */
  public set expirationDate(value: Date) {
    this._expirationDate = value;
  }

  /**
   * Setter birthPlace
   * @param {string} value
   */
  public set birthPlace(value: string) {
    this._birthPlace = value;
  }

  /**
   * Setter title
   * @param {string} value
   */
  public set title(value: string) {
    this._title = value;
  }

  /**
   * Setter birthProvince
   * @param {string} value
   */
  public set birthProvince(value: string) {
    this._birthProvince = value;
  }

  /**
   * Setter birthDistrict
   * @param {string} value
   */
  public set birthDistrict(value: string) {
    this._birthDistrict = value;
  }

  /**
   * Setter postcode
   * @param {string} value
   */
  public set postcode(value: string) {
    this._postcode = value;
  }

  /**
   * Setter address
   * @param {string} value
   */
  public set address(value: string) {
    this._address = value;
  }

  /**
   * Setter district
   * @param {string} value
   */
  public set district(value: string) {
    this._district = value;
  }

  /**
   * Setter province
   * @param {string} value
   */
  public set province(value: string) {
    this._province = value;
  }

  /**
   * Setter nation
   * @param {string} value
   */
  public set nation(value: string) {
    this._nation = value;
  }

  /**
   * Setter houseNumber
   * @param {string} value
   */
  public set houseNumber(value: string) {
    this._houseNumber = value;
  }

  /**
   * Setter fc
   * @param {string} value
   */
  public set fc(value: string) {
    this._fc = value;
  }

  /**
   * Setter regime
   * @param {string} value
   */
  public set regime(value: string) {
    this._regime = value;
  }

  /**
   * Setter registeredOffice
   * @param {string} value
   */
  public set registeredOffice(value: string) {
    this._registeredOffice = value;
  }

  /**
   * Setter website
   * @param {string} value
   */
  public set website(value: string) {
    this._website = value;
  }

  /**
   * Setter IVAentry
   * @param {string} value
   */
  public set IVAentry(value: string) {
    this._IVAentry = value;
  }

  /**
   * Setter username
   * @param {string} value
   */
  public set username(value: string) {
    this._username = value;
  }

  /**
   * Setter password
   * @param {string} value
   */
  public set password(value: string) {
    this._password = value;
  }

  /**
   * Setter superuser
   * @param {boolean} value
   */
  public set superuser(value: boolean) {
    this._superuser = value;
  }

  /**
   * Setter photo
   * @param {any} value
   */
  public set photo(value: any) {
    this._photo = value;
  }

  /**
   * Setter invoicingLogo
   * @param {any} value
   */
  public set invoiceLogo(value: any) {
    this._invoiceLogo = value;
  }

  /**
   * Setter expiredSubscription
   * @param {boolean} value
   */
  public set expiredSubscription(value: boolean) {
    this._expiredSubscription = value;
  }

  /**
   * Setter freeUser
   * @param {boolean} value
   */
  public set freeUser(value: boolean) {
    this._freeUser = value;
  }

  /**
   * Setter maxCollaborators
   * @param {number} value
   */
  public set maxCollaborators(value: number) {
    this._maxCollaborators = value;
  }

  /**
   * Setter isStudio
   * @param {boolean} value
   */
  public set isStudio(value: boolean) {
    this._isStudio = value;
  }

  /**
   * Setter cem
   * @param {string} value
   */
  public set cem(value: string) {
    this._cem = value;
  }
}

export interface UtenteDTO {
  _id?: string;
  id?: string;
  idconvenzione?: string;
  percentualesconto?: string;
  nome?: string;
  cognome?: string;
  specializzazione?: string;
  notefattura?: string;
  aifiId?: string;
  regionediaffiliazioneaifi?: string;
  sesso?: string;
  telefono?: string;
  email?: string;
  cellulare?: string;
  datanascita?: Date;
  natoa?: string;
  titolo?: string;
  provinciadinascita?: string;
  comunedinascita?: string;
  cap?: string;
  indirizzo?: string;
  comune?: string;
  provincia?: string;
  nazione?: string;
  numerocivico?: string;
  cf?: string;
  regime?: string;
  sedelegale?: string;
  sitoweb?: string;
  piva?: string;
  username?: string;
  password?: string;
  superuser?: boolean;
  foto?: any; // foto
  logofatture?: any; // foto
  abbonamentoscaduto?: boolean;
  utentegratuito?: boolean;
  n_collaboratori_da_inserire?: number;
  dataregistrazione?: Date;
  studio?: boolean;
  modified?: Date;
  pec?: string;
  datascadenza?: Date;
  /**
   * E-Invoicing (Fatturazione Elettronica)
   */
  codiceidentificativo?: string;
  /**
   * Iscrizione Albo
   */
  albo_id?: string;
  comune_albo?: string;
  /**
   * Integrazione con tessera sanitaria
   */
  tesserasanitaria_username?: string;
  tesserasanitaria_password?: string;
  tesserasanitaria_PIN?: string;
  tesserasanitaria_codiceregionale?: string;
  tesserasanitaria_codiceasl?: string;
  tesserasanitaria_codicessa?: string;
  tesserasanitaria_enabled?: boolean;
  lastlogin?: string;
  documentidisksize?: string;
  episodi?: number;
  eventi?: number;
  fatture?: number;
  anagrafe?: number
  // calendar
  calendar: boolean;
  calendar_id: string;
  oauth_token: string;
}

import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class SessionManagerService {
  private USERNAME_TOKEN_DEFAULT = "fisioweb\\username";
  private PASSWORD_TOKEN_DEFAULT = "fisioweb\\password";
  private _password?: string;
  private _username?: string;
  private _usernameToken?: string;
  private _passwordToken?: string;
  private _useSessionStorage: boolean;
  private _adminpassword?: string;

  constructor() {
    this.useStorage(this.USERNAME_TOKEN_DEFAULT, this.PASSWORD_TOKEN_DEFAULT);
  }

  /**
   * Getter this.session.adminpassword
   * @return {string}
   */
  public get adminpassword(): string | null {
    return this._adminpassword;
  }

  /**
   * Setter this.session.adminpassword
   * @param {string} value
   */
  public set adminpassword(value: string) {
    this._adminpassword = value;
  }

  /**
   * Getter this.session.password
   * @return {string}
   */
  public get password(): string | null {
    let p = this._passwordToken
      ? (this.useSessionStorage ? sessionStorage : localStorage).getItem(
        this._passwordToken
      )
      : this._password;
    return p == "null" ? null : p;
  }

  /**
   * Getter this.session.username
   * @return {string}
   */
  public get username(): string | null {
    if (this._usernameToken) {
      let u;
      if (this.useSessionStorage) {
        u = sessionStorage.getItem(this._usernameToken);
      } else {
        u = localStorage.getItem(this._usernameToken);
      }
      return u == "null" ? null : u;
    } else {
      return this._username;
    }
  }

  /**
   * Setter this.session.password
   * @param {string} value
   */
  public set password(value: string) {
    this._password = value;
    if (this._passwordToken) {
      (this.useSessionStorage ? sessionStorage : localStorage).setItem(
        this._passwordToken,
        value
      );
    }
  }

  /**
   * Setter this.session.username
   * @param {string} value
   */
  public set username(value: string) {
    this._username = value;
    if (this._usernameToken) {
      (this.useSessionStorage ? sessionStorage : localStorage).setItem(
        this._usernameToken,
        value
      );
    }
  }

  addAdminSessionData(object: Object): Object {
    return {
      sessioneutente: "admin",
      sessionepassword: this.adminpassword,
      ...object,
    };
  }

  public addSessionData(object: Object): Object {
    return {
      sessioneutente: this.username,
      sessionepassword: this.password,
      ...object,
    };
  }

  private useStorage(
    usernameToken?: string,
    passwordToken?: string,
    useSessionInsteadOfLocal: boolean = false
  ) {
    this._usernameToken = usernameToken;
    this._passwordToken = passwordToken;
    this._useSessionStorage = useSessionInsteadOfLocal;
  }

  public clean() {
    localStorage.setItem(this._usernameToken, null);
    localStorage.setItem(this._passwordToken, null);
    sessionStorage.setItem(this._usernameToken, null);
    sessionStorage.setItem(this._passwordToken, null);
  }

  /**
   * Getter useSessionStorage
   * @return {boolean}
   */
  public get useSessionStorage(): boolean {
    return this._useSessionStorage;
  }

  /**
   * Setter useSessionStorage
   * @param {boolean} value
   */
  public set useSessionStorage(value: boolean) {
    this._useSessionStorage = value;
    if (value) {
      sessionStorage.setItem(this._usernameToken, this.username);
      sessionStorage.setItem(this._passwordToken, this.password);
      localStorage.setItem(this._usernameToken, null);
      localStorage.setItem(this._passwordToken, null);
    } else {
      localStorage.setItem(this._usernameToken, this.username);
      localStorage.setItem(this._passwordToken, this.password);
      sessionStorage.setItem(this._usernameToken, null);
      sessionStorage.setItem(this._passwordToken, null);
    }
  }
}

import { FormGroup } from '@angular/forms';

import { Base } from './base';
import { Physiotherapist } from './physiotherapist';

export class Collaborator extends Base {
  private _physiotherapist: Physiotherapist;
  private _physiotherapistID: string;
  private _name: string;
  private _lastName: string;
  private _email: string;
  private _birthDate: Date;
  private _telephone: string;
  private _birthProvince: string;
  private _birthDistrict: string;
  private _postcode: string;
  private _fc: string;
  private _IVAentry: string;
  private _activated: boolean;
  private _activationDate: Date[];
  calendarId: string;
  oauthToken: string;

  constructor(source?: CollaboratoreDTO) {
    super(source);
    if (source) {
      this.id = source._id;
      this.updatedAt = source.modified;
      this.physiotherapistID = source.idFisioterapista;
      this.name = source.nome;
      this.lastName = source.cognome;
      this.email = source.email;
      this.birthDate = source.datanascita;
      this.telephone = source.telefono;
      this.birthProvince = source.provinciadinascita;
      this.birthDistrict = source.comunedinascita;
      this.postcode = source.cap;
      this.fc = source.cf;
      this.IVAentry = source.piva;
      this.activated = source.attivato;
      this.oauthToken = source.oauth_token;
      this.calendarId = source.calendar_id;
    }
  }

  public toDTO(): CollaboratoreDTO {
    let c: CollaboratoreDTO = {
      _id: this.id,
      idFisioterapista: this.physiotherapistID,
      nome: this.name,
      cognome: this.lastName,
      email: this.email,
      datanascita: this.birthDate,
      telefono: this.telephone,
      provinciadinascita: this.birthProvince,
      comunedinascita: this.birthDistrict,
      cap: this.postcode,
      cf: this.fc,
      piva: this.IVAentry,
      attivato: this.activated,
      dataattivazione: this.activationDate,
      modified: this.updatedAt,
      oauth_token: this.oauthToken,
      calendar_id: this.calendarId,
    };
    return c;
  }

  static fromFormGroup(formGroup: FormGroup): Collaborator {
    const model = formGroup.value;
    let collaborator = new Collaborator();
    collaborator.name = model.name;
    collaborator.lastName = model.surname;
    collaborator.email = model.email;
    return collaborator;
  }

  get fullName(): string {
    return `${this.name} ${this.lastName}`;
  }

  /**
   * Getter physiotherapist
   * @return {Physiotherapist}
   */
  public get physiotherapist(): Physiotherapist {
    return this._physiotherapist;
  }

  /**
   * Getter physiotherapistID
   * @return {string}
   */
  public get physiotherapistID(): string {
    return this._physiotherapist
      ? this._physiotherapist.id
      : this._physiotherapistID;
  }

  /**
   * Setter physiotherapist
   * @param {Physiotherapist} value
   */
  public set physiotherapist(value: Physiotherapist) {
    this._physiotherapist = value;
    this._physiotherapistID = value ? value.id : null;
  }

  /**
   * Setter physiotherapistID
   * @param {string} value
   */
  public set physiotherapistID(value: string) {
    this._physiotherapistID = value;
    if (this._physiotherapist && this._physiotherapist.id !== value) {
      this._physiotherapist = null;
    }
  }

  /**
   * Getter name
   * @return {string}
   */
  public get name(): string {
    return this._name;
  }

  /**
   * Getter lastName
   * @return {string}
   */
  public get lastName(): string {
    return this._lastName;
  }

  /**
   * Getter email
   * @return {string}
   */
  public get email(): string {
    return this._email;
  }

  /**
   * Getter birthDate
   * @return {Date}
   */
  public get birthDate(): Date {
    return this._birthDate;
  }

  /**
   * Getter telephone
   * @return {string}
   */
  public get telephone(): string {
    return this._telephone;
  }

  /**
   * Getter birthProvince
   * @return {string}
   */
  public get birthProvince(): string {
    return this._birthProvince;
  }

  /**
   * Getter birthDistrict
   * @return {string}
   */
  public get birthDistrict(): string {
    return this._birthDistrict;
  }

  /**
   * Getter postcode
   * @return {string}
   */
  public get postcode(): string {
    return this._postcode;
  }

  /**
   * Getter fc
   * @return {string}
   */
  public get fc(): string {
    return this._fc;
  }

  /**
   * Getter IVAentry
   * @return {string}
   */
  public get IVAentry(): string {
    return this._IVAentry;
  }

  /**
   * Getter activated
   * @return {boolean}
   */
  public get activated(): boolean {
    return this._activated;
  }

  /**
   * Getter activationDate
   * @return {Date[]}
   */
  public get activationDate(): Date[] {
    return this._activationDate;
  }

  /**
   * Setter name
   * @param {string} value
   */
  public set name(value: string) {
    this._name = value;
  }

  /**
   * Setter lastName
   * @param {string} value
   */
  public set lastName(value: string) {
    this._lastName = value;
  }

  /**
   * Setter email
   * @param {string} value
   */
  public set email(value: string) {
    this._email = value;
  }

  /**
   * Setter birthDate
   * @param {Date} value
   */
  public set birthDate(value: Date) {
    this._birthDate = value;
  }

  /**
   * Setter telephone
   * @param {string} value
   */
  public set telephone(value: string) {
    this._telephone = value;
  }

  /**
   * Setter birthProvince
   * @param {string} value
   */
  public set birthProvince(value: string) {
    this._birthProvince = value;
  }

  /**
   * Setter birthDistrict
   * @param {string} value
   */
  public set birthDistrict(value: string) {
    this._birthDistrict = value;
  }

  /**
   * Setter postcode
   * @param {string} value
   */
  public set postcode(value: string) {
    this._postcode = value;
  }

  /**
   * Setter fc
   * @param {string} value
   */
  public set fc(value: string) {
    this._fc = value;
  }

  /**
   * Setter IVAentry
   * @param {string} value
   */
  public set IVAentry(value: string) {
    this._IVAentry = value;
  }

  /**
   * Setter activated
   * @param {boolean} value
   */
  public set activated(value: boolean) {
    this._activated = value;
  }

  /**
   * Setter activationDate
   * @param {Date[]} value
   */
  public set activationDate(value: Date[]) {
    this._activationDate = value;
  }
}

export interface CollaboratoreDTO {
  _id?: string;
  idFisioterapista?: string;
  nome?: string;
  cognome?: string;
  email?: string;
  datanascita?: Date;
  telefono?: string;
  provinciadinascita?: string;
  comunedinascita?: string;
  cap?: string;
  cf?: string;
  piva?: string;
  attivato?: boolean;
  dataattivazione?: Date[];
  modified?: Date;
  oauth_token: string;
  calendar_id: string;
}

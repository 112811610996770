import {
  AfterViewInit,
  Component,
  Inject,
  OnInit,

} from "@angular/core";
import { FormControl, Validators } from "@angular/forms";
import {
  MAT_DIALOG_DATA,
  MatDialogRef,
} from "@angular/material";
import { Router } from "@angular/router";
import { AuthService } from "src/app/commons/services/auth.service";


@Component({
  selector: "password-dialog",
  templateUrl: "./password-dialog.component.html",
  styleUrls: ["./password-dialog.component.scss"],
})
export class PasswordDialogComponent implements OnInit, AfterViewInit {

  password = new FormControl('', Validators.required);
  adminPassword: string

  constructor(
    public dialogRef: MatDialogRef<PasswordDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,   
  ) {}

  ngOnInit() {}

  ngAfterViewInit() {}

  adminLogin() {
    this.close(this.password.value)
  }
  
  close(value?: string) {
    this.dialogRef.close(value);
  }
}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from '../../../../environments/environment';
import { CollaboratoreDTO } from '../../models/collaborator';
import { ResponseBase } from './response-base';
import { SessionManagerService } from './session-manager.service';

@Injectable({
  providedIn: "root"
})
export class CollaboratorsService {
  constructor(
    private httpClient: HttpClient,
    private session: SessionManagerService
  ) { }

  private get ROUTES() {
    return {
      collaborators: environment.baseUrl + "/api/collaboratori",
      collaborator: environment.baseUrl + "/api/collaboratore",
      searchCollaborator: environment.baseUrl + "/api/ricercacollaboratore",
      simpleCollaborator: environment.baseUrl + "/api/identificacollaboratore"
    };
  }

  public getCollaborators(): Observable<CollaboratoreDTO[]> {
    return this.httpClient.get<CollaboratoreDTO[]>(
      this.ROUTES.collaborators +
      `/${this.session.username}/${this.session.password}`
    );
  }

  public searchCollaborators(regex: string): Observable<CollaboratoreDTO[]> {
    return this.httpClient.post<CollaboratoreDTO[]>(
      this.ROUTES.searchCollaborator,
      this.session.addSessionData({ search: regex })
    );
  }

  public getSimplifiedCollaborator(
    collaboratorID: string
  ): Observable<{ nome: string; cognome: string; email: string }> {
    return this.httpClient.get<{
      nome: string;
      cognome: string;
      email: string;
    }>(
      this.ROUTES.simpleCollaborator +
      `/${this.session.username}/${this.session.password}/${collaboratorID}`
    );
  }

  public getCollaborator(collaboratorID: string): Observable<CollaboratoreDTO> {
    return this.httpClient.get<CollaboratoreDTO>(
      this.ROUTES.collaborator +
      `/${this.session.username}/${this.session.password}/${collaboratorID}`
    );
  }

  public addCollaborator(
    collaborator: CollaboratoreDTO
  ): Observable<ResponseBase> {
    return this.httpClient.post(
      this.ROUTES.collaborator,
      this.session.addSessionData(collaborator)
    );
  }

  public updateCollaborator(
    collaborator: CollaboratoreDTO
  ): Observable<ResponseBase> {
    return this.httpClient.put(
      this.ROUTES.collaborator + `/${collaborator._id}`,
      this.session.addSessionData(collaborator)
    );
  }

  public deleteCollaborator(collaboratorID: string): Observable<ResponseBase> {
    return this.httpClient.delete(
      this.ROUTES.collaborator +
      `/${this.session.username}/${this.session.password}/${collaboratorID}`
    );
  }
}

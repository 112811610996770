import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule, MatDialogModule, MatSnackBarModule } from '@angular/material';

import { ConfirmDialogComponent } from './components/confirm-dialog/confirm-dialog.component';
import { ErrorAlertComponent } from './components/error-alert/error-alert.component';
import { ErrorSnackComponent } from './components/error-snack/error-snack.component';
import { LoaderComponent } from './components/loader/loader.component';
import { NotFoundComponent } from './components/not-found/not-found.component';
import { CurrencyLocalPipe } from './pipes/currency-local.pipe';

@NgModule({
  imports: [CommonModule, MatButtonModule, MatDialogModule, MatSnackBarModule, FlexLayoutModule],
  exports: [
    LoaderComponent,
    NotFoundComponent,
    ConfirmDialogComponent,
    ErrorAlertComponent,
    CurrencyLocalPipe,
    ErrorSnackComponent
  ],
  entryComponents: [ConfirmDialogComponent, ErrorSnackComponent],
  declarations: [
    LoaderComponent,
    NotFoundComponent,
    ConfirmDialogComponent,
    ErrorAlertComponent,
    CurrencyLocalPipe,
    ErrorSnackComponent
  ]
})
export class SharedModule { }

import { Component, OnInit, OnDestroy } from "@angular/core";
import { ErrorLogService } from "src/app/commons/services/error-log.service";
import { Subscription } from "rxjs";

const ALERT_WAIT_TIME = 5000; // ms

@Component({
  selector: "app-error-alert",
  templateUrl: "./error-alert.component.html",
  styleUrls: ["./error-alert.component.scss"]
})
export class ErrorAlertComponent implements OnInit, OnDestroy {
  public closed = true;
  current: string;
  private sub: Subscription;
  private lastTimeout;

  constructor(private errorLog: ErrorLogService) {}

  ngOnInit() {
    this.sub = this.errorLog.errorMessageSource.subscribe(message => {
      clearTimeout(this.lastTimeout);
      const p = message.indexOf("(");
      if (p > 0) {
        this.current = message.substr(0, p);
      } else {
        this.current = message;
      }
      this.closed = false;
      this.lastTimeout = setTimeout(() => this.close(), ALERT_WAIT_TIME);
    });
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

  public close() {
    this.closed = true;
  }
}

import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { finalize, first } from 'rxjs/operators';
import { PhysiotherapistService } from 'src/app/commons/services/physiotherapist.service';

import { ConfirmService } from './../../../commons/services/confirm.service';

@Component({
  selector: "app-register",
  templateUrl: "./register.component.html",
  styleUrls: ["./register.component.scss"]
})
export class RegisterComponent implements OnInit {
  private loadingSubject = new BehaviorSubject<boolean>(false);
  public loading$ = this.loadingSubject.asObservable();

  form: FormGroup;
  error = false;
  errorMessage = "";
  returnUrl: string;

  constructor(
    private fb: FormBuilder,
    private confirmService: ConfirmService,
    private router: Router,
    private physioService: PhysiotherapistService
  ) { }

  ngOnInit() {
    // get return url from route parameters or default to '/'
    this.returnUrl = "/login";

    this.form = this.fb.group(
      {
        name: ["", Validators.required],
        surname: ["", Validators.required],
        studioName: ["", Validators.required],
        email: [
          "",
          Validators.compose([Validators.required, Validators.email])
        ],
        password: ["", [Validators.required]],
        confirmPassword: [""],
        registrationType: ["physiotherapist", [Validators.required]],
        termsConditionsCheck: ["", [Validators.required]]
      },
      { validator: this.validateForm }
    );

    this.form.get("registrationType").valueChanges.subscribe((value) => {
      this.form.get("name").disable();
      this.form.get("surname").disable();
      this.form.get("studioName").disable();
      if (value == 'physiotherapist') {
        this.form.get("name").enable();
        this.form.get("surname").enable();
      } else {
        this.form.get("studioName").enable();
      }
    })
  }

  private validateForm(group: FormGroup) {
    let password = group.controls.password.value;
    let confirmPassword = group.controls.confirmPassword.value;

    group.controls.confirmPassword.setErrors((password && password != confirmPassword) ? { confirmPasswordDifferent: true } : null)

  }

  public registrationTypeSelected(registrationType: string): boolean {
    return registrationType == this.form.value.registrationType;
  }

  register() {
    if (this.form.valid) {
      this.loadingSubject.next(true);
      this.error = false;
      if (this.form.value.registrationType == "physiotherapist") {
        this.physioService
          .registerNew(
            this.form.value.name,
            this.form.value.surname,
            this.form.value.email,
            this.form.value.password
          )
          .pipe(
            finalize(() => this.loadingSubject.next(false)),
            first()
          )
          .subscribe(
            result => {
              if (result.success) {
                console.log("Registration completed successfully", result);
                this.router.navigate([this.returnUrl]);
              } else {
                this.error = true;
                this.errorMessage = result.message;
                console.log("Registration failed", result.message);
              }
            },
            error => {
              this.error = true;
              this.confirmService.showErrorMessage("Errore durante la registrazione", error);
              console.error("Error", error);
            }
          );
      } else {
        this.physioService
          .registerNewStudio(
            this.form.value.studioName,
            this.form.value.email,
            this.form.value.password
          )
          .pipe(
            finalize(() => this.loadingSubject.next(false)),
            first()
          )
          .subscribe(
            result => {
              if (result.success) {
                console.log("Registration completed successfully", result);
                this.router.navigate([this.returnUrl]);
              } else {
                this.error = true;
                this.errorMessage = "";
                console.log("Registration failed", result.message);
              }
            },
            error => {
              this.error = true;
              console.error("Error", error);
            }
          );
      }
    }
  }
}

import { environment } from '../environments/environment';

export function initGTMScript() {
  const tagManagerConfig = environment["tagManager"];
  if (tagManagerConfig) {
    const head = document.getElementsByTagName('head')[0];

    const GTMScript = document.createElement('script');
    GTMScript.async = true;
    GTMScript.innerHTML = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl+ '&gtm_auth=${tagManagerConfig["auth"]}&gtm_preview=${tagManagerConfig["env"]}&gtm_cookies_win=x';f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','${tagManagerConfig["code"]}');`;

    head.insertBefore(GTMScript, head.firstChild);
  }
}

import { FlexLayoutModule } from '@angular/flex-layout';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from "@angular/forms";
import { LoginRoutingModule } from './login-routing.module';
import { LoginComponent } from './login.component';
import { AppMaterialModule } from '../app-material/app-material.module';
import { RecoverPasswordComponent } from './recover-password/recover-password.component';
import { RegisterComponent } from './register/register.component';
import { MatRadioModule } from '@angular/material';

@NgModule({
  imports: [
    FlexLayoutModule,
    CommonModule,
    LoginRoutingModule,
    ReactiveFormsModule,
    AppMaterialModule,
    MatRadioModule
  ],
  declarations: [LoginComponent, RecoverPasswordComponent, RegisterComponent]
})
export class LoginModule { }

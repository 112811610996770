import { FormGroup } from '@angular/forms';

import { Base } from './base';

export class Person extends Base {
  name: string;
  lastName: string;
  gender: string;
  email: string;
  telephone: string;
  mobilePhone: string;
  birthDate: string;
  birthProvince: string;
  birthDistrict: string;
  fc: string;
  weight: number;
  IVAentry: string;
  job: string;
  familyDoctor: string;
  notes: string;
  deleted: boolean;
  height: number;
  jobType: string;
  cem: string;
  postcode: string;
  address: string;
  district: string;
  province: string;
  nation: string;
  houseNumber: string;
  identifier: string;

  constructor(source?: PersonaDTO) {
    super(source);
    if (source) {
      this.id = source._id;
      this.name = source.nome;
      this.lastName = source.cognome;
      this.gender = source.sesso;
      this.email = source.email;
      this.telephone = source.telefono;
      this.mobilePhone = source.cellulare;
      this.birthDate = source.datanascita;
      this.birthProvince = source.provinciadinascita;
      this.birthDistrict = source.comunedinascita;
      this.fc = source.cf;
      this.weight = source.peso;
      this.IVAentry = source.piva;
      this.job = source.lavoro;
      this.familyDoctor = source.medicodifamiglia;
      this.notes = source.note;
      this.deleted = source.eliminato;
      this.height = source.altezza;
      this.jobType = source.tipodilavoro;
      this.cem = source.pec;
      this.postcode = source.cap;
      this.address = source.indirizzo;
      this.district = source.comune;
      this.province = source.provincia;
      this.nation = source.nazione;
      this.houseNumber = source.numerocivico;
      this.identifier = source.codiceidentificativo;
      this.updatedAt = source.modified;
    }
  }

  public toDTO(): PersonaDTO {
    let p: PersonaDTO = {
      _id: this.id,
      nome: this.name,
      cognome: this.lastName,
      sesso: this.gender,
      telefono: this.telephone,
      cellulare: this.mobilePhone,
      email: this.email,
      datanascita: this.birthDate,
      provinciadinascita: this.birthProvince,
      comunedinascita: this.birthDistrict,
      cf: this.fc,
      piva: this.IVAentry,
      altezza: this.height,
      peso: this.weight,
      medicodifamiglia: this.familyDoctor,
      lavoro: this.job,
      tipodilavoro: this.jobType,
      note: this.notes,
      eliminato: this.deleted,
      modified: this.updatedAt,
      pec: this.cem,
      cap: this.postcode,
      indirizzo: this.address,
      comune: this.district,
      provincia: this.province,
      nazione: this.nation,
      numerocivico: this.houseNumber,
      codiceidentificativo: this.identifier
    };
    return p;
  }

  static fromFormGroup(formGroup: FormGroup): Person {
    const formModel = formGroup.value;

    let person: Person = new Person();
    person.name = formModel.name;
    person.lastName = formModel.lastname;
    person.gender = formModel.gender;
    person.fc = formModel.fiscalCode;
    person.IVAentry = formModel.IVAentry;
    person.birthDate = formModel.birthDate;

    person.familyDoctor = formModel.familyDoctor;
    person.job = formModel.job;
    person.height = formModel.height;
    person.weight = formModel.weight;
    person.notes = formModel.notes;

    person.telephone = formModel.telephone;
    person.cem = formModel.cem;
    person.mobilePhone = formModel.mobile;
    person.identifier = formModel.identifier;
    person.email = formModel.email;
    person.identifier = formModel.identificationCode;

    person.address = formModel.streetName;
    person.houseNumber = formModel.streetNumber;
    person.district = formModel.district;
    person.postcode = formModel.postCode;
    person.province = formModel.province;
    person.nation = formModel.country;

    return person;
  }

  public get fullName(): string {
    return `${this.name} ${this.lastName}`;
  }
}

export interface PersonaDTO {
  _id?: string;
  nome?: string;
  cognome?: string;
  sesso?: string;
  telefono?: string;
  cellulare?: string;
  email?: string;
  datanascita?: string;
  provinciadinascita?: string;
  comunedinascita?: string;
  cf?: string;
  piva?: string;
  altezza?: number;
  peso?: number;
  medicodifamiglia?: string;
  lavoro?: string;
  tipodilavoro?: string;
  note?: string;
  eliminato?: boolean;
  modified?: Date;
  pec?: string;
  cap?: string;
  indirizzo?: string;
  comune?: string;
  provincia?: string;
  nazione?: string;
  numerocivico?: string;
  /**
   * E-Invoicing (Fatturazione Elettronica)
   */
  codiceidentificativo?: string;
}
